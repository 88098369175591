<!-- @format -->
<template>
  <div>
    <div class="flex rounded-md mb-8 py-2 items-center justify-between gap-2">
      <div class="w-1/2">
        <div class="text-xl font-medium">Model Day Task</div>
        <div class="text-gray-400 font-medium">{{ currentShift }}</div>
      </div>
      <div
        class="bg-white flex w-1/2 border border-gray-200 shadow-sm rounded-l-full rounded-r-md justify-between items-center cursor-pointer"
        @click="navigateToModelDay()"
      >
        <div class="flex items-center gap-2 py-4">
          <div
            class="flex items-center bg-teal bg-opacity-10 text-teal rounded-full w-11 h-11 pl-2 mx-4"
          >
            <span class="material-icons">checklist_outlined</span>
          </div>
          <div class="text-xl font-medium">{{ currentShiftTitle }} Shift</div>
        </div>
        <div class="mr-4">
          <span class="bg-blue text-blue bg-opacity-20 px-2 py-1 rounded-md">
            {{ shiftStatus }}
          </span>
        </div>
      </div>
    </div>
    <div
      :key="surveyResponseOrder"
      class="xl:relative rounded-md overflow-hidden shadow-lg h-auto bg-white border-gray-100 py-10"
    >
      <div class="flex justify-between">
        <div class="pl-18">
          <span class="text-4xl font-medium">{{ practiceName }}</span>
        </div>
        <div class="flex space-x-4 justify-end pr-10 w-1/4">
          <v-select
            class="w-full"
            label="label"
            placeholder="Show By"
            :searchable="false"
            :options="shoyByOptions"
            v-model="surveyResponseOrder"
          >
          </v-select>
        </div>
      </div>
      <span class="text-2xl tracking-tighter font-medium text-gray-500 pl-18"
        >Survey Responses {{ practiceSurveyCount }}</span
      >
      <div
        class="border-b-2 border-[#ECECEC] drop-shadow-2xl w-full pb-10"
      ></div>
      <SurveyResponseChart
        :key="optionStats"
        :order="surveyResponseOrder?.value"
        :data="optionStats"
        :loader="loadingQuestionTable"
        class="h-full"
      />
    </div>
    <div class="flex mt-10 gap-4">
      <div class="w-1/3">
        <div
          class="p-5 bg-white w-full rounded-md gap-4 flex flex-col justify-center items-center"
        >
          <ComplianceListMA
            :heading="'New Audit'"
            :items="compliances['audits']?.items?.slice(0, 1)"
            :loader="loader"
            :isDash="true"
            :key="compliances['audits']?.items?.slice(0, 1)"
          />
          <ComplianceListMA
            :heading="'New Risk Assessment'"
            :items="compliances['risk-assessment']?.items?.slice(0, 1)"
            :loader="loader"
            :isDash="true"
            :key="compliances['risk-assessment']?.items?.slice(0, 1)"
          />
          <ComplianceListMA
            :heading="'New Policies'"
            :items="compliances['policies']?.items?.slice(0, 1)"
            :loader="loader"
            :isDash="true"
            :key="compliances['policies']?.items?.slice(0, 1)"
          />
        </div>
      </div>
      <div class="w-2/3">
        <div class="bg-white rounded-md pt-4 border border-gray-200">
          <Tabs
            :key="tabsData"
            :tabsData="tabsData"
            :activeTabIndex="tabIndex"
            @index="switchTab"
            :isDash="true"
          />
          <div class="pt-2" :key="tabIndex">
            <div v-if="tableLoader">
              <TableSkeletonLoader :loader="tableLoader" :cols="4" :rows="6" />
            </div>
            <div v-else>
              <div v-if="tabIndex == 0">
                <ComplaintsListTable
                  :tableHeading="tableHeader"
                  :key="AllComplaints?.data"
                  :tabIndex="tabIndex"
                  :tableBody="AllComplaints?.data?.slice(0, 6)"
                  :isManager="true"
                  :isDash="true"
                />
              </div>
              <div v-else>
                <ComplimentListTable
                  :key="complimentsMa?.data"
                  :tableHeading="tableHeader"
                  :tableBody="complimentsMa?.data?.slice(0, 6)"
                  :isManager="true"
                  :isDash="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import SurveyResponseChart from "@/components/newUi/charts/SurveyResponseChart.vue";
import ComplianceListMA from "@/components/newUi/Compliance/ComplianceListMA.vue";
import ComplaintsListTable from "@/components/newUi/tables/ComplaintsListTable.vue";
import Tabs from "@/components/newUi/baseComponents/Tabs.vue";
import ComplimentListTable from "@/components/newUi/tables/ComplimentListTable.vue";
import TableSkeletonLoader from "@/components/newUi/atoms/TableSkeletonLoader.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const practiceId = localStorage.getItem("practice");
const practiceName = localStorage.getItem("practice_name");
const shoyByOptions = ref([
  { label: "Ascending", value: "asc" },
  { label: "Descending", value: "desc" },
]);
const tabIndex = ref(0);
const router = useRouter();
const tableLoader = ref(false);
const tabsData = ref([
  {
    name: "Complaints",
    value: 0,
  },
  { name: "Compliments", value: 1 },
]);

const switchTab = (index) => {
  tabIndex.value = index;
};

const navigateToModelDay = () => {
  router.push({ name: "ma-ModelDayDash" });
};

const tableHeader = computed(() => {
  switch (tabIndex.value) {
    case 0:
      return ["Ref No", "Complaint Title", "Subject", "Status"];
    case 1:
      return ["Patient NHS No", "Source of Compliment", "Date"];

    default:
      return ["Ref No", "Complaint Title", "Subject", "Status"];
  }
});

const currentShift = computed(() => {
  switch (activeShift.value?.shift) {
    case 1:
      return "Morning 8:00 AM";
    case 2:
      return "Mid-Day 11:00 AM";
    case 3:
      return "Evening 3:00 PM";
    default:
      return "Morning 8:00 AM";
  }
});

const currentShiftTitle = computed(() => {
  switch (activeShift.value?.shift) {
    case 1:
      return "Morning";
    case 2:
      return "Mid-Day";
    case 3:
      return "Evening";
    default:
      return "Morning";
  }
});

const shiftStatus = computed(() => {
  if (
    activeShift.value.instances_count &&
    activeShift.value.instances_count.length > 0
  ) {
    if (parseInt(activeShift.value.instances_count[0].pending) === 0) {
      return "In-Progress";
    } else {
      return "Completed";
    }
  }
  return "In-Progress";
});

const surveyResponseOrder = ref(null);

const loader = ref(false);

const compliances = computed(() => {
  return store.getters["maComplianceCalender/getAllComplianceItems"];
});

const practiceSurveyCount = computed(() => {
  return store.getters["MAPatientSurveys/getPracticeSurveyCount"];
});

const optionStats = computed(() => {
  return store.getters["MAPatientSurveys/getOptionStats"];
});

const AllComplaints = computed(() => {
  return store.getters["maComplaints/getAllComplaints"];
});

const complimentsMa = computed(() => {
  return store.getters["maCompliments/getAllCompliments"];
});

const activeShift = computed(() => {
  return store.getters["maModelDay/getActiveShift"];
});

onMounted(async () => {
  loader.value = true;
  tableLoader.value = true;
  store.dispatch("MAPatientSurveys/fetchPracticeSurveyCount");
  await store
    .dispatch("maComplianceCalender/fetchAllCompliances", {
      practiceId,
    })
    .then(() => {
      loader.value = false;
    });
  await store.dispatch("MAPatientSurveys/fetchOptionStats");
  await store
    .dispatch("maComplaints/fetchAllComplaints", {
      page: 1,
      practice: practiceId,
    })
    .then(() => {
      tableLoader.value = false;
    });
  await store.dispatch("maCompliments/fetchAllComplimentsMa", {
    practice: practiceId,
    page: 1,
  });

  await store.dispatch("maModelDay/fetchActiveShift", { practice: practiceId });
});
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 8px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
  color: white;
}

::v-deep .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 40px);
}

::v-deep .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow: hidden;
}
::v-deep ul li {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
